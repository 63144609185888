<template>
<b-overlay
    :show="isLoading"
  >
  <div class="misc-wrapper">
    <b-link class="brand-logo">
      <vuexy-logo />
      <h2 class="brand-text text-primary ml-1">Vuexy</h2>
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">
          {{ transactionMessage.title }}
        </h2>
        <p class="mb-2">
          {{ transactionMessage.text }}
        </p>
        <b-button
          variant="primary"
          class="mb-1 btn-sm-block"
          to="/subscription-plan"
          >Back to Home</b-button
        >
        <b-img fluid :src="imgUrl" alt="Not authorized page" />
      </div>
    </div>
  </div>
</b-overlay>
</template>

<script>
/* eslint-disable global-require */
import { BLink, BImg, BButton, BOverlay } from "bootstrap-vue";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import store from "@/store/index";
import { getHomeRouteForLoggedInUser } from "@/auth/utils";

export default {
  components: {
    BLink,
    BImg,
    BButton,
    BOverlay,
    VuexyLogo,
  },
  data() {
    return {
      downImg: require("@/assets/images/pages/not-authorized.svg"),
      transactionMessage: {
        title: "Plaese wait...",
        text: "",
      },
      isLoading:false,
    };
  },
  mounted() {

    this.getTransactionStatus();
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require("@/assets/images/pages/not-authorized-dark.svg");
        return this.downImg;
      }
      return this.downImg;
    },
  },
  methods: {
    loginRoute() {
      const user = JSON.parse(localStorage.getItem("userData"));
      return getHomeRouteForLoggedInUser(user ? user.role : null);
    },
    getTransactionStatus() {
      this.isLoading = true;
      store
        .dispatch("app-customer/getTransactionStatus", this.$route.query.token)
        .then((response) => {
          console.log("our response1", response.data.payment_status);
          this.isLoading = false
          if (response.data.payment_status) {
            this.isLoading = false
            this.transactionMessage.title = "Transaction Successfull!";
            this.transactionMessage.text =
              "You have successfully suscribried to the plan!!";
          } else {
            this.transactionMessage.title = response.data.message + " " +  "transaction Fail!";
            this.transactionMessage.text = "Your transaction is Failed!!";
          }
        })
        .catch((error) => {
          this.isLoading = false
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-misc.scss";
</style>
